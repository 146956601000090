<template>
  <div class="ModifyViewers">
<!--    修改直播间观看人次-->
    <ToastIcon v-if="!isEnterpriseWeChat"></ToastIcon>
    <div v-else>
      <div class="select">
        <van-cell class="selectCell"  :title="cellTitle" is-link @click="show = true" />
        <van-action-sheet
            v-model="show"
            :actions="option"
            @select="selectLive"/>
        <!--     <div style="width: 96%;margin: a
        uto">-->
        <!--       <el-select @change="selectLive" style="width: 100%;" v-model="value" clearable  placeholder="会议选择">-->
        <!--         <el-option-->
        <!--             style="width: 360px"-->
        <!--             v-for="item in option"-->
        <!--             :key="item.id"-->
        <!--             :label="item.title"-->
        <!--             :value="item.id">-->
        <!--         </el-option>-->
        <!--       </el-select>-->
        <!--     </div>-->
      </div>
      <div v-if="value" class="content">
        <div class="content-top">
          <div class="content-top-live">
            <div style="width: 50%">
              <img :src="liveContent.coverUrl" />
            </div>
            <div class="title-font">
              <div class="title-title" v-if="liveContent.title">{{liveContent.title}}</div>
              <div class="title-time" v-if="liveContent.liveDate">会议时间：{{liveContent.liveDate}}</div>
            </div>
          </div>
          <div style="width: 100%;text-align: center">
            <el-input-number
                style="width: 80%;margin-top: 15px"
                v-model="num"
                :min="0" :step="1000">
            </el-input-number>
            <el-button
                @click="onSubmit"
                style="margin-top: 15px;width: 80%;margin-bottom: 15px"
                type="primary">提交</el-button>
            <div class="perLook" v-if="nowCount">当前观看人次：{{nowCount}}</div>
          </div>
        </div>
        <div v-if="value">
          <div class="content-mid" v-if="liveContent.tolUpdCount">总：<span style="color: #45A5E6">{{liveContent.tolUpdCount}}人次</span></div>
          <div class="content-boot" v-for="(item,index) in liveContent.liveStreamViewersList" :key="index">
            <div class="left">
              <div style="font-size: 12px;">{{item.createdName}}</div>
              <div style="margin-top: 5px">{{item.createdTime}}</div>
            </div>
            <div class="right">+ {{item.addCount}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {postRoomCount} from "@/service/examApi";
import {getRoomCount,postLiveStreamViewers,getOneRoomCount} from "@/service/document_api";
import ToastIcon from "@/components/ToastIcon";
import {isEnterpriseWeChat} from "@/utils/isEnterpriseWeChat";
export default {
  name:'ModifyViewers',
  components: {ToastIcon},
  data(){
    return{
      isEnterpriseWeChat:isEnterpriseWeChat(),
      show:false,
      isShow:true,
      cellTitle:'会议选择',
      option:[],
      value:'',
      oldListValue:'',
      num:0,
      nowCount:0,
      list:{},
      liveContent:[],
    }
  },
  created() {
    if(this.isEnterpriseWeChat) {
      this.getLive()
    }
  },
  methods:{
    //获取直播间列表
    async getLive() {
      const response = await getRoomCount()
      if (response.code === 0) {
        this.option = response.data
      } else {
        this.$message.warning(response.message)
      }
    },
    //外部接口  --直播间刷人次接口
    async onSubmit() {
      let user = JSON.parse(localStorage.getItem('EnterpriseWeChat'))
      this.oldListValue = this.value
     if(this.num){
       let data = {
         "room":this.value,
         "count":this.num,
       }
       const response = await postRoomCount(data)
       if(response.code === 0){
         this.nowCount = response.data.nowCount
         await this.addLiveStreamViewers(this.value,this.num,user.YunIcuUserId,this.cellTitle)
       }else {
         this.$message.warning(response.message)
       }
     }
    },
    //添加刷观看人次记录
    async addLiveStreamViewers(liveStreamId,addCount,userId,cellTitle) {
      const response = await postLiveStreamViewers(liveStreamId,addCount,userId)
      if(response.code === 0){
        this.$message.success('操作成功~')
        // 刷新界面数据
        await this.getLive()
        let item = {
          name:cellTitle,
          id:liveStreamId
        }
        await this.selectLive(item)
      }
    },
    //选择会议更新界面内容
    // selectLive(value) {
    //   //清空
    //   this.num = 0
    //   //判断选择的直播间有没有更新，更新的情况下隐藏当前直播观看记录
    //   if(this.oldListValue !== value){
    //     this.nowCount = 0
    //   }
    //   this.getLiveOne(value)
    // },
    selectLive(item){
      this.value = item.id
      this.cellTitle = item.name
      this.show = false;

      //清空
      this.num = 0
      //判断选择的直播间有没有更新，更新的情况下隐藏当前直播观看记录
      if(this.oldListValue !== item.id){
        this.nowCount = 0
      }
      this.getLiveOne(item.id)
    },
    // 获取单个会议
    async getLiveOne(id) {
      const response = await getOneRoomCount(id)
      if (response.code === 0) {
        this.liveContent = response.data
      } else {
        this.$message.warning(response.message)
      }
    },

  }
}
</script>

<style lang="scss">
.selectCell{
  border: 1px solid #e0e1e5;
  margin: auto;
  width: 96%;
  margin-top: 10px;
  color: #acacb1;
}
.ModifyViewers{
  width: 100%;
  color: #333333;
  .select{
    margin-top: 15px;
  }
  .content{
    .content-top{
      width: 95%;
      margin: auto;
      .content-top-live{
        height: 100px;
        margin-top: 15px;
        margin-bottom: 20px;
        display: flex;
        justify-content: space-between;
        img{
          width: 90%;
          border-radius: 10px;
          max-height: 100px;
          border: 1px solid #f5f5f5;
        }
        .title-font{
          width: 50%;position: relative;height: 100px;
          .title-title{
            font-size: 18px;
            font-weight: 650;
            overflow: hidden;
            -webkit-line-clamp: 2;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
          }
          .title-time{
            font-size: 14px;color: #808080;position: absolute;bottom: 10px;
          }
        }
      }
      .perLook{
        font-size: 14px;color: #808080;margin-bottom: 15px;
      }
    }
    .content-mid{
      font-size: 14px;
      height: 50px;
      line-height: 50px;
      background-color: #F8F8F8;
      padding-left: 20px;
    }
    .content-boot{
      font-size: 14px;
      width: 95%;
      height: 50px;
      display: flex;
      color: #808080;
      margin: auto;
      border-bottom: 1px solid #f5f5f5;
      font-size: 14px;
      .left{
        width: 85%;
        margin-left: 10px;
        padding-top: 5px;
      }
      .right{
        width: 15%;
        line-height: 50px;
        text-align: center;
      }
    }
  }
}
</style>
